.overview {
  background-color: var(--container-color);
  padding-top: 2rem;
  margin-top: 1rem;
  padding-bottom: 3rem;
}

.overview-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18vw;
  min-width: max-content;
  gap: 5px;
  padding-bottom: 1rem;
}

.overview-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  min-width: fit-content;
  padding: 12px 16px;
  background: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  color: black;
}

.overview-btn:hover,
.overview-btn.active {
  background: #20655f;
  color: white;
}

.overview hr {
  content: "";
  width: 90vw;
  outline: 0;
  border-bottom: 1px rgb(241, 240, 240);
}

.overview-section {
  width: 85vw;
  display: block;
}

.overview-overview-section {
  display: flex;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 20px;
}

.overview-overview-left,
.overview-overview-right {
  width: 50%;
  padding-top: 1rem;
}

.overview-section li {
  text-decoration: dotted;
}

.overview .hidden {
  display: none;
}

.overview table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}
.overview .table-head {
  background-color: var(--title-color);
  color: white;
}

.overview th,
td {
  padding: 15px;
  text-align: center;
  border: 1px solid rgb(165, 165, 165);
}

@media screen and (max-width: 560px) {
  .overview-overview-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 00px;
    width: 90%;
    margin-left: 1rem;
  }
  .overview-overview-left,
  .overview-overview-right {
    width: 100%;
    padding-top: 0rem;
  }

  .overview-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    min-width: max-content;
    gap: 5px;
    padding-bottom: 1rem;
  }
}
