.outlet {
  display: grid;
  justify-content: center;
  width: 100vw;
  margin: auto;
}

.outlet .section {
  width: 80vw;
}

.outlet-settings {
  display: flex;
  justify-content: space-between;

  margin-top: 2rem;
}

.outlet-settings-right {
  display: flex;
  align-items: center;
  gap: 40px;
}

.outlet-filter button {
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #888;
  background-color: transparent;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 7rem;
  justify-content: center;
}

.outlet-products {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 80px;
  column-gap: 10px;
}

.outlet-btn {
  margin-top: 4rem;
}

.outlet-btn button {
  background: var(--title-color);
  color: white;
  margin-bottom: 2rem;
}

.outlet .hidden {
  display: none;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 1140px) {
  .outlet .section {
    width: 90vw;
  }
}
@media screen and (max-width: 768px) {
  .outlet .section {
    width: 95vw;
  }

  .outlet-products {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin: 1rem;
  }
}
