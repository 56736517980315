.breadcrumb {
  width: 82vw;
  display: flex;
  margin: auto;
}

.breadcrumb-content {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #5e5e5e;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 40px;
  justify-content: left;
  width: 100%;
}

.breadcrumb span {
  text-transform: capitalize;
}

.breadcrumb-link {
  color: black;
  text-decoration: none;
}

.breadcrumb-link:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
  cursor: pointer;
}

@media screen and (max-width: 1140px) {
  .breadcrumb {
    width: 90vw;
  }
}
@media screen and (max-width: 800px) {
  .breadcrumb {
    display: none;
  }
}
