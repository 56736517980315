/*=============== VARIABLES CSS ===============*/
:root {
  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --title-color: #20655f;
  --subtitle-color: #f8aa27;
  --container-color: #f8f4ed;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 200;
  margin-bottom: 0.5rem;
  color: var(--title-color);
  font-family: "Young Serif";
  display: inline;
  text-transform: capitalize;
  text-align: center;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
  margin-bottom: 2rem;
}

.pineapples {
  background: url("./assets/background.png");
}

.custom-button {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #888;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 8rem;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3;
}

.link {
  text-decoration: none;
}

.no-scroll {
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .section-title {
    font-size: 2rem !important;
  }

  .section {
    padding-top: 2rem;
    padding-bottom: 0rem !important;
    margin-bottom: 0rem !important;
  }
}

button {
  color: black;
}
