.complete {
  width: 80vw;
  margin: auto;
}

.complete-category {
  display: flex;
  align-items: center;
}

.complete-category span {
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: "Young Serif";
  color: #20655f;
  font-weight: 300;
  font-size: 1.5rem;
}

.complete hr {
  width: 5rem;
  height: 2px;
  border-radius: 10px;
  background-color: #20655f;
}

.complete-items-container {
  overflow: hidden;
}

.complete-items {
  margin-top: 40px;
  display: flex;
  gap: 20px;
  margin: 2rem;
}

.complete-item {
  margin-top: 40px;
  display: flex;
  gap: 30px;
}

.complete-all-btn-container {
  margin-left: auto; /* Push the button to the right end */
  margin-right: 8rem;
  margin-top: -4rem;
  margin-bottom: 1rem;
}

.complete-all-btn {
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #888;
  cursor: pointer;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 8rem;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3;
}

.complete-all-btn:hover {
  background-color: #f8aa27;
  opacity: 80%;
  transition: 0.3;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 1140px) {
  .complete {
    width: 95vw;
  }

  .complete-items {
    gap: 10px;
  }
}
@media screen and (max-width: 700px) {
  .complete {
    width: 90vw;
  }

  .complete-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .complete {
    width: 98vw;
  }

  .complete-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    margin: 1rem;
  }
}
