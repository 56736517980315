.header {
  background-color: #20655f;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.header-text {
  color: white;
  font-size: 0.8rem;
  letter-spacing: 0.1rem;
}

@media screen and (max-width: 450px) {
  .header-text {
    font-size: 0.6rem;
  }
}
