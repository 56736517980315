.hero {
  width: 100%;
}

.hero-top {
  height: 70vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  background-image: url("../../assets/hero-img-1.jpeg");
}

.hero-slogan {
  background-color: #f8aa27;
  opacity: 80%;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  font-family: "Love Ya Like A Sister";
  letter-spacing: 1.5rem;
}

.hero-banner {
  padding: 2rem;
  margin-left: 12vw;
  margin-top: 10vh;
  height: 10rem;
  display: grid;
  font-family: "Young Serif";
}

.hero-banner h1 {
  color: #f8aa27;
  font-size: 3rem;
  margin-bottom: 0.5rem;
}
.hero-banner h3 {
  color: #20655f;
  font-size: 2rem;
  margin-top: 0;
}

.hero-buttons {
  display: flex;
  justify-content: left;
  column-gap: 1rem;
  text-decoration: none;
}
.hero-banner button {
  color: white;
  font-size: 1rem;
  background-color: #f8aa27;
  display: inline;
  border: none;
  border-radius: 0.5rem;
  height: 3rem;
  width: 9rem;
  cursor: pointer;
}

.hero-button {
  text-decoration: none;
}

@media screen and (max-width: 1400px) {
  .hero-slogan {
    letter-spacing: 1rem;
  }

  .hero-banner {
    padding: 2rem;
    margin-left: 8vw;
    margin-top: 17vh;
    height: 10rem;
    display: grid;
    font-family: "Young Serif";
  }
}
@media screen and (max-width: 1140px) {
  .hero-slogan {
    letter-spacing: 0.8rem;
    padding: 1.2rem;
  }
}
@media screen and (max-width: 950px) {
  .hero-slogan {
    letter-spacing: 0.7rem;
    padding: 1.2rem;
    font-size: 1.2rem;
  }

  .hero-banner h1 {
    color: #f8aa27;
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  .hero-banner h3 {
    color: #20655f;
    font-size: 1.5rem;
    margin-top: 0;
  }

  .hero-banner button {
    font-size: 0.9rem;
    height: 2.5rem;
    width: 7rem;
  }

  .hero-top {
  }
}
@media screen and (max-width: 800px) {
  .hero-slogan {
    letter-spacing: 0.6rem;
    padding: 1rem;
    font-size: 1rem;
  }
}
@media screen and (max-width: 680px) {
  .hero-slogan {
    letter-spacing: 0.4rem;
    padding: 1rem;
    font-size: 1rem;
  }
}
@media screen and (max-width: 576px) {
  .hero-slogan {
    font-size: 1rem;
    letter-spacing: 0.3rem;
    display: flex;
    text-align: center;
    padding: 1rem;
  }

  .hero-top {
    display: flex;
    justify-content: center;
    align-self: center;
  }

  .hero-banner {
    padding: 2rem;
    margin-left: 0vw;
    height: 10rem;
    display: grid;
    justify-content: center;
    align-items: center;
    font-family: "Young Serif";
    text-align: center;
  }

  .hero-banner h1 {
    font-size: 1.5rem;
  }
  .hero-banner h3 {
    font-size: 1.4rem;
  }

  .hero-buttons {
    display: grid;
    justify-content: center;
    row-gap: 1rem;
  }

  .hero-banner button {
    font-size: 0.9rem;
    height: 2.5rem;
    width: 50vw;
  }
}
