.productdisplay {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 83vw;
  margin: auto;
  padding-bottom: 3rem;
}

.productdisplay-left {
  display: grid;
  grid-template-columns: 0.325fr 1fr;
  gap: 15px;
  width: 100%;
}

.productdisplay-img-list {
  width: 100%;
  row-gap: 0px;
}

.productdisplay-img-list img {
  border-radius: 4px;
  width: 100%;
  height: auto;
}

.productdisplay-main-img {
  width: 100%;
  height: auto;
}

.productdisplay-right {
  margin: 0;
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
}

.productdisplay-right h1 {
  color: #3d3d3d;
  font-size: 2rem;
  font-weight: 600;
  font-family: "Young Serif";
}

.productdisplay-right-stars {
  display: flex;
  align-items: center;
  gap: 5px;
  zoom: 1.2;
}

.productdisplay-right-prices {
  display: flex;
  margin: 20px 0;
  gap: 30px;
  font-size: 1.5rem;
}

.productdisplay-right-price-old {
  color: #818181;
  text-decoration: line-through;
}

.productdisplay-right-price-new {
  color: black;
  font-weight: 600;
}

.productdisplay-right-description {
  font-family: Poppins;
}

.productdisplay-right button {
  padding: 20px 40px;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: Poppins;
  color: white;
  background: #f8aa27;
  margin-bottom: 40px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}

.productdisplay-right-category {
  margin-top: 10px;
}

.productdisplay-right-category span {
  font-weight: 600;
}

.productdisplay-labels {
  margin-bottom: 10px;
}

.productdisplay-label {
  padding: 7px;
  background-color: lightgray;
  border-radius: 5px;
  opacity: 90%;
  color: black;
  font-size: 0.7rem;
  font-weight: 500;
  margin-right: 4px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.sale {
  background-color: rgb(215, 76, 76);
  color: white;
}

.recycled {
  background-color: #3b5e2e;
  color: white;
}

.pd-shipping-box {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 1rem;
  display: grid;
  row-gap: 0.7rem;
}

.pd-shipping-line {
  font-family: Poppins;
}

.pd-shipping-icon {
  color: black;
  padding: 5px;
  padding-right: 1rem;
  font-size: 1.3rem;
}

.productdisplay-img-list-2 {
  width: 100%;
  row-gap: 0px;
  display: none;
}

@media screen and (max-width: 1140px) {
  .productdisplay {
    width: 90vw;
    grid-template-columns: 0.9fr 1fr;
    align-items: start;
  }

  .productdisplay-right {
    margin-left: 2rem;
  }

  .productdisplay-left {
    grid-template-columns: 1fr;
    row-gap: 5px;
  }
  .productdisplay-img-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5px;
  }
}
@media screen and (max-width: 800px) {
  .productdisplay-img-list {
    grid-template-columns: 1fr;
    row-gap: 5px;
  }

  .productdisplay {
    grid-template-columns: 3fr 1fr;
  }

  .productdisplay-main-img {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .productdisplay {
    grid-template-columns: 1fr;
  }

  .productdisplay-img-list {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 5px;
  }

  .productdisplay-right {
    margin-left: 0px;
  }
}

@media screen and (max-width: 400px) {
  .productdisplay-left {
    width: 85%;
    justify-content: center;
    margin: auto;
  }

  .productdisplay-right {
    width: 85%;
    margin: auto;
  }
}
