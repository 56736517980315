.search {
  z-index: 1000;
}

.search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the alpha (transparency) value as needed */
  z-index: 999; /* Make sure it's above other content */
}

.search-header {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 4px;
  width: 40rem;
  height: 3rem;
}

.search-input {
  width: 90%;
  border: none;
  font-size: 1rem;
  outline: none; /* Remove the default focus outline */
  padding-left: 1rem;
}

.search-bar-icons {
  display: flex;
  width: 10%;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-right: 1rem;
}

.search-erase {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
  width: 1rem;
}

.search-erase-icon {
  border: 1px solid lightgrey;
  border-radius: 20%;
  font-size: 0.5rem;
  padding: 4px;
  color: grey;
}

.search-erase-icon:hover {
  color: black;
}

.search-icon:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.search-close-icon {
  right: -2rem;
  position: relative;
  cursor: pointer;
}

.search-close-icon:hover {
  color: rgb(75, 75, 75);
}

.search-content {
  height: fit-content;
  background-color: white;
  padding: 2rem;
}

.search-results {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: start;
  width: 60vw;
  margin: auto;
  gap: 20px;
}

.search-item {
  width: 100%;
  margin-top: 0;
}

.search-no-results {
  color: black;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  margin: auto;
}

@media screen and (max-width: 1300px) {
  .search-results {
    width: 80vw;
  }
}

@media screen and (max-width: 1100px) {
  .search-results {
    gap: 10px;
  }
}
@media screen and (max-width: 900px) {
  .search-bar {
    width: 25rem;
  }
}

@media screen and (max-width: 840px) {
  .search-bar {
    height: 2rem;
  }
}
@media screen and (max-width: 800px) {
  .search-results {
    width: 80vw;
  }
}

@media screen and (max-width: 550px) {
  .search-results {
    grid-template-columns: 1fr 1fr;
    width: 90vw;
  }

  .search-bar {
    width: 70vw;
  }

  .search-2,
  .search-3 {
    display: none;
  }
}
