.signup {
  width: 100%;
  height: 85vh;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.signup-container {
  width: 25vw;
  margin: auto;
  display: grid;
  justify-content: center;
  align-items: center;
  position: relative;
}

.signup-title {
  margin: auto;
  margin-bottom: 2rem;
}

.signup-fields input {
  height: 3rem;
  width: 100%;
  padding-left: 20px;
  border: 1px solid var(--title-color);
  outline: none;
  color: #5c5c5c;
  font-size: 18px;
  border-radius: 4px;
  margin-bottom: 1rem;
  position: relative;
  box-sizing: border-box;
}

.signup-container button {
  width: 100%;
  box-sizing: border-box;
  height: 3rem;
  color: white;
  background: var(--title-color);
  border: none;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 1rem;
}

.signup-container button:disabled {
  background: #5c5c5c;
  cursor: not-allowed;
}

.signup-login {
  margin-top: 20px;
  color: #5c5c5c;
  font-size: 1.1rem;
  font-weight: 500;
}

.signup-login-btn {
  color: var(--subtitle-color);
  font-weight: 600;
}

.signup-tooltip-text {
  position: absolute;
  z-index: 1;
  border: 1px solid #5c5c5c;
  border-radius: 4px;
  color: #5c5c5c;
  font-size: 0.8rem;
  padding: 0.5rem;
  width: 10rem;
  left: -14rem;
  margin-top: -4rem;
  background-color: var(--container-color);
  opacity: 0;
  transition: opacity 0.3s;
}

.signup-tooltip-icon {
  position: absolute;
  left: -2rem;
  color: #5c5c5c;
  margin-top: 1.2rem;
}

.signup-tooltip-icon:hover ~ .signup-tooltip-text {
  visibility: visible !important;
  opacity: 1;
}

.signup-agree {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 1rem;
  font-weight: 500;
}

.signup-checkbox {
  accent-color: var(--subtitle-color);
}

.signup-sign {
  position: absolute;
  font-size: 1rem;
  right: 1rem;
  z-index: 1;
  margin-top: 5rem;
}

.hide {
  display: none;
}

.valid {
  display: inline;
  color: green;
}

.invalid {
  display: inline;
  color: red;
}

@media screen and (max-width: 1200px) {
  .signup-container {
    width: 40vw;
  }
}

@media screen and (max-width: 920px) {
  .signup-container {
    width: 50vw;
  }
}
@media screen and (max-width: 760px) {
  .signup-container {
    width: 70vw;
  }
}
@media screen and (max-width: 500px) {
  .signup-container {
    width: 90vw;
    transform: scale(0.9);
  }
}
@media screen and (max-width: 350px) {
  .signup-container {
    width: 90vw;
    transform: scale(0.8);
  }

  .signup {
    padding-top: 0rem;
    padding-bottom: 0;
  }
}
