.newsletter {
  background-color: #f8aa27;
  height: 40vh;
  display: flex;
}

.newsletter-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 40vw;
}

.newsletter-title {
  margin: auto;
  color: #20655f;
  font-family: "Young Serif";
}
.newsletter-subtitle {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #20655f;
  font-family: "Poppins";
}

.newsletter-form {
  width: 100%;
}
.newsletter-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 1rem;
}

.newsletter-input {
  height: 2rem;
  width: 90%;
  padding: 0.5rem 1rem 0.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  border: 2px solid #20655f;
  background-color: antiquewhite;
  outline: none;
  box-sizing: content-box;
}

.newsletter-btn-box {
  display: flex;
  justify-content: center;
}

.newsletter-btn {
  justify-content: center;
  background-color: #20655f;
  color: white;
  align-items: center;
  margin-top: 1rem;
  height: 2rem;
  width: 100%;
  padding: 1rem 1rem 3rem 1rem;
  font-size: 1.5rem;
  border-radius: 0.5rem;
  border: 2px solid #20655f;
  outline: none;
  cursor: pointer;
}

@media screen and (max-width: 1140px) {
  .newsletter {
    height: 35vh;
  }
  .newsletter-box {
    width: 45vw;
  }
}
@media screen and (max-width: 960px) {
  .newsletter {
    height: 40vh;
  }

  .newsletter-box {
    width: 60vw;
  }

  .newsletter-box {
    transform: scale(1);
  }
}

@media screen and (max-width: 760px) {
  .newsletter {
    height: 45vh;
  }

  .newsletter-box {
    width: 60vw;
  }

  .newsletter-inputs {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 0.5rem;
    margin: auto;
  }
  .newsletter-box {
    transform: scale(0.9);
  }
}

@media screen and (max-width: 500px) {
  .newsletter {
    height: 43vh;
  }

  .newsletter-box {
    width: 80vw;
  }
  .newsletter-box {
    transform: scale(0.7);
  }
}
@media screen and (max-width: 350px) {
  .newsletter {
    height: 40vh;
  }

  .newsletter-box {
    width: 95vw;
  }
  .newsletter-box {
    transform: scale(0.7);
  }
}
