.shop-category {
  padding-top: 4rem;
  display: grid;
  justify-content: center;
  padding-bottom: 4rem;
}

.shop-category-title {
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: "Young Serif";
  color: #20655f;
  font-weight: 300;
  font-size: 2.5rem;
  justify-self: center;
  text-transform: capitalize;
}

.shop-category-settings {
  display: flex;
  align-self: center;
  justify-content: space-between;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  height: 2.5rem;
}

.shop-category-settings-right {
  display: flex;
  align-items: center;
  gap: 40px;
}

.shop-category-filter {
  display: flex;
}

.shop-category-sort {
  display: flex;
  height: 100%;
}

.shop-category-filter-list {
  margin-left: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border: 1px solid grey;
  border-radius: 4px;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: var(--container-color);
  box-sizing: border-box;
  height: 100%;
}

.shop-category-filter-item {
  cursor: pointer;
}

.shop-category-filter button {
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #888;
  background-color: transparent;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 7rem;
  justify-content: center;
}

.shop-category-active-filter {
  color: var(--title-color);
  font-weight: 600;
}

.shop-category-indexSort {
  display: flex;
  margin: 0px 170px;
  justify-content: space-between;
  align-items: center;
}

.shop-category-indexSort p span {
  font-weight: 600;
}

.shop-category-products {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  display: grid;
  width: 80vw;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 80px;
  column-gap: 10px;
}

.shop-category-loadmore {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 150px auto;
  width: 233px;
  height: 69px;
  border-radius: 75px;
  background: #ededed;
  color: #787878;
  font-size: 18px;
  font-weight: 500;
}

.shop-category-count {
  margin-left: auto;
  margin-right: 3rem;
  display: flex;
  align-items: center;
}

.shop-category-no-results {
  display: flex;
  justify-content: center;
  margin-top: 8rem;
  margin-bottom: 6rem;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 1140px) {
  .shop-category-products {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
}
@media screen and (max-width: 768px) {
  .shop-category-products {
    grid-template-columns: 1fr 1fr !important;
  }
}
@media screen and (max-width: 450px) {
  .shop-category-products {
    grid-template-columns: 1fr !important;
  }
  .shop-category-count {
    margin-right: 1rem;
  }
}
