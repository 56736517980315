.dropdown {
  display: flex;
}

.dropdown button {
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #888;
  background-color: transparent;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  height: 100%;
}

.dropdown-content {
  position: absolute;
  min-width: 200px;
  background-color: #333;
  opacity: 90%;
  border-radius: 10px;
  border: 1px solid black;
  padding: 5px;
  margin-right: 3rem;
  margin-top: 3rem;
  z-index: 100;
}

.dropdown-content li {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 2rem;
  font-size: 1rem;
  color: white;
  text-decoration: none;
  display: block;
  border-radius: 5px;
  cursor: pointer;
}

.dropdown-content li span {
  padding-left: 5px;
  padding-right: 5px;
}

.hiddden {
  visibility: hidden;
}

.dropdown-content li:hover {
  background-color: #e28282;
}

@media screen and (max-width: 768px) {
  .dropdown button {
    width: 2rem;
  }
  .dropdown-button-name {
    display: none;
  }

  .dropdown-content {
    margin-left: -11rem;
  }
}
