.bestSeller {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  padding-bottom: 2rem;
  width: 85vw;
  margin: auto;
}

.bestSeller-category {
  display: flex;
  align-items: center;
}

.bestSeller-category span {
  padding-left: 1rem;
  padding-right: 1rem;
  color: #20655f;
  font-weight: 300;
  font-size: 1.5rem;
}

.bestSeller h1 {
  font-size: 2.5rem;
  font-weight: 200;
  margin-bottom: 0.5rem;
  color: #20655f;
  font-family: "Young Serif";
  display: inline;
}

.bestSeller hr {
  width: 5rem;
  height: 2px;
  border-radius: 10px;
  background-color: #20655f;
}

.bestSeller-items-container {
  width: 100%; /* Adjust the width as needed */
  overflow-x: scroll;
}

.bestSeller-items {
  margin-top: 40px;
  display: flex;
  gap: 10px;
  margin: 2rem;
}

.bestSeller-item {
  flex: 0 0 calc(25% - 20px); /* 25% is for 4 items, adjust the gap value */
}

.bestSeller-all-btn-container {
  margin-left: auto; /* Push the button to the right end */
  margin-top: -4rem;
  margin-bottom: 1rem;
  margin-right: 2rem;
  right: 0;
}

.bestSeller-all-btn {
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #888;
  cursor: pointer;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 8rem;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3;
}

.bestSeller-all-btn:hover {
  background-color: #f8aa27;
  opacity: 80%;
  transition: 0.3;
}

.bestSeller-arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.arrow {
  width: 40px;
  height: 40px;
  background-color: #f8aa27;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s;
}

.arrow.disabled {
  cursor: no-drop;
}

.arrow:hover {
  background-color: #e69121;
}

.item-count {
  font-size: 1.2rem;
  color: #20655f;
  margin: 0 10px;
}

/* FontAwesome icons styling (assuming you are using FontAwesome icons) */
.fa-chevron-left,
.fa-chevron-right {
  color: white;
  font-size: 18px;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 1140px) {
  .bestSeller {
    width: 95vw;
  }
}
@media screen and (max-width: 1140px) {
  .bestSeller-item {
    flex: 0 0 calc(30% - 20px); /* 25% is for 4 items, adjust the gap value */
  }
}
@media screen and (max-width: 768px) {
  .bestSeller {
    width: 100vw;
  }

  .bestSeller-items {
    gap: 10px;
    margin: 1rem;
    margin-left: 2rem;
  }

  .bestSeller-item {
    flex: 0 0 calc(40% - 20px); /* 25% is for 4 items, adjust the gap value */
  }
}
@media screen and (max-width: 450px) {
  .shop-category-count {
    margin-right: 1rem;
  }

  .bestSeller-all-btn-container {
    display: block;
    margin-left: 0; /* Push the button to the right end */
    margin-top: 0.5rem;
    margin-bottom: 0rem;
    margin-right: 0rem;
    right: 0;
  }

  .bestSeller h1 {
    font-size: 2rem;
    margin-bottom: 0rem;
  }

  .bestSeller hr {
    width: 2rem;
    height: 1px;
  }

  .bestSeller-all-btn {
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 0.9rem;
  }

  .bestSeller-item {
    flex: 0 0 calc(50% - 20px); /* 25% is for 4 items, adjust the gap value */
  }

  .arrow {
    width: 30px;
    height: 30px;
  }

  .fa-chevron-left,
  .fa-chevron-right {
    color: white;
    font-size: 14px;
  }
}

@media screen and (max-width: 380px) {
  .bestSeller-item {
    flex: 0 0 calc(80% - 20px); /* 25% is for 4 items, adjust the gap value */
  }
}
