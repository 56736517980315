.header-navbar {
  z-index: 1;
  height: 110px;
}

.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
}

.navbar {
  box-shadow: 0 1px 3px -2px black;
  height: 82px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-main {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 16px;
}

.nav-logo {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.nav-logo-img {
  color: #171717;
  height: 3.5rem;
}

.nav-logo-text {
  color: #171717;
  font-size: 2rem;
  font-weight: 600;
  font-family: "Love Ya Like A Sister";
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 50px;
  font-size: 1.2rem;
}

.nav-menu hr {
  border: 1px;
  width: 80%;
  height: 2px;
  border-radius: 15px;
  background: #f8aa27;
  margin-top: 0.2rem;
}

.nav-item {
  text-decoration: none;
  color: black;
}

.nav-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: black;
}

.nav-icon {
  color: black;
  font-size: 1.8rem;
  text-decoration: none;
  cursor: pointer;
}

.nav-login-cart button:active {
  background: #f3f3f3;
}

.no-scroll {
  overflow: hidden;
}

.nav-cart-count {
  width: 1.1rem;
  height: 1.1rem;
  margin-left: 1rem;
  margin-top: -2.5rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  font-size: 0.7rem;
  background: #f8aa27;
  color: black;
}

.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

.navbar-small {
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  z-index: 1000;
}

.navbar-small-menu {
  display: none;
  position: fixed;
  left: 0;
  background-color: white;
  top: 0px;
  margin-top: 60px;
  height: 100%;
  width: 40%;
  z-index: -1;
  transition: left 0.5s; /* Add the same transition property */
}

.navbar-small-menu.hidden {
  left: -50%;
  transition: left 0.5s; /* Add the same transition property */
}

.nav-small-menu-list {
  list-style-type: none;
  margin-top: 2.9rem;
  padding-right: 1rem;
}

.navbar-black {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
}

.nav-small-item {
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  font-weight: 400;
  margin-bottom: 0.6rem;
  margin-right: 0.5rem;
  align-items: center;
}

.nav-small-item:hover {
  color: var(--title-color);
}

.nav-small-item-icon {
  font-size: 1.5rem;
}

.no-scroll {
  overflow: hidden;
}

.nav-small-login-container {
  background-color: var(--container-color);
}

.nav-small-login {
  margin-left: 2rem;
  padding-top: 2rem;
}

.nav-icon-login {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.5rem !important;
}

.nav-icon-login span {
  font-size: 1.2rem;
}

@media screen and (max-width: 1140px) {
  .nav-menu {
    gap: 30px;
    font-size: 1rem;
  }

  .nav-cart-count {
    margin-left: 0.9rem;
    margin-top: -2.3rem;
  }

  .nav-icon {
    font-size: 1.5rem;
  }

  .nav-logo {
    transform: scale(0.9);
  }
}

@media screen and (max-width: 844px) {
  .header-navbar {
    height: 92px;
  }
  .navbar {
    height: 60px;
  }
  .navbar-small {
    display: flex;
  }

  .navbar-main {
    display: none;
  }

  .navbar-small-menu {
    display: grid;
  }
}

@media screen and (max-width: 450px) {
  .navbar-small-menu {
    width: 100%;
  }

  .nav-small-menu-list {
    margin-right: 1rem;
  }
  .navbar-small-menu.hidden {
    left: -120%;
    transition: left 0.5s; /* Add the same transition property */
  }

  .nav-icon {
    font-size: 1.3rem;
  }

  .nav-signup-icon {
    display: none;
  }

  .nav-icons {
    gap: 0.3rem;
  }
}
