.item {
  width: 100%;
  height: auto;
}

.item-img {
  width: 100%;
  height: auto;
  border-radius: 3px;
  margin-bottom: 1rem;
}

.item-link {
  text-decoration: none;
}

.item-labels {
  margin-top: -60px;
  margin-left: 10px;
  position: inherit;
  margin-bottom: 1.4rem;
  height: 1rem;
}

.item-label {
  padding: 6px;
  background-color: white;
  border-radius: 3px;
  opacity: 90%;
  color: black;
  font-size: 0.7rem;
  font-weight: 500;
  margin-right: 3px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.sale {
  background-color: rgb(215, 76, 76);
  color: white;
}

.recycled {
  background-color: #3b5e2e;
  color: white;
}

.item-description {
  display: grid;
}

.item p {
  margin: 6px 0;
}

.item-prices {
  display: flex;
  gap: 20px;
  color: black;
  margin-top: 5px;
}

.item-price-new {
  font-size: 18px;
  font-weight: 500;
}

.item-price-old {
  font-size: 18px;
  font-weight: 500;
  text-decoration: line-through;
}

.item:hover {
  transform: scale(1.02);
  transition: 0.6;
}

.rating {
  display: flex;
  gap: 0.5rem;
}
