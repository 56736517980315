.banner {
  width: 85vw;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
}

.banner-img {
  height: auto;
  width: 85vw;
  z-index: 0;
  margin: auto;
}

.banner-text-box {
  display: block;
  position: absolute;
  padding: 8rem;
  z-index: 1;
  width: 40%;
  margin-top: 4rem;
}

.banner-title {
  font-size: 3rem;
  color: white;
  font-family: "Young Serif";
}

.banner-subtitle {
  font-size: 2rem;
  color: white;
}

.banner-btn {
  color: white;
  font-size: 1rem;
  background-color: #f8aa27;
  display: inline;
  border: none;
  border-radius: 0.5rem;
  width: 15rem;
  height: 3rem;
  cursor: pointer;
  text-decoration: none;
}

.blabla {
  text-decoration: none;
}

@media screen and (max-width: 1140px) {
  .banner-text-box {
    padding: 6rem;
    width: 70%;
    margin-top: 1rem;
  }

  .banner-title {
    font-size: 2.5rem;
  }

  .banner-img {
    height: auto;
    width: 100vw;
  }

  .banner-btn {
    width: 10rem;
    height: 2.5rem;
  }

  .banner {
    width: 100vw;
  }
}

@media screen and (max-width: 844px) {
  .banner-text-box {
    padding: 10vw;
    width: 60vw;
    margin-top: 1rem;
  }
  .banner-title {
    font-size: 1.5rem;
  }

  .banner {
    width: 100vw;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 576px) {
  .banner-text-box {
    width: 70vw;
    margin-top: 1rem;
    padding: 2rem;
  }
  .banner-title {
    font-size: 1.2rem;
  }

  .banner-btn {
    width: 8rem;
    height: 2rem;
    font-size: 0.8rem;
  }

  .banner {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .banner-img {
    height: 10rem;
  }
}

@media screen and (max-width: 350px) {
  .banner-text-box {
    width: 80vw;
    margin-top: 0rem;
    padding: 1rem;
  }
  .banner-title {
    font-size: 1rem;
  }

  .banner-btn {
    width: 7rem;
    height: 1.5rem;
    font-size: 0.7rem;
  }
}
