.cartitems {
  width: 80vw;
  padding-top: 2rem;
  padding-bottom: 5rem;
  display: flex;
  justify-content: center;
  margin: auto;
}

.cartitems hr {
  height: 3px;
  background: #e2e2e2;
  border: 0;
}

.cartitems-format-main {
  display: grid;
  grid-template-columns: 0.5fr 2fr 0.5fr 0.5fr 1fr 0.5fr 1fr 0.2fr;
  align-items: center;
  gap: 75px;
  color: #454545;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  text-align: center;
}

.cartitems-format {
  padding-top: 10px;
  font-size: 17px;
  font-weight: 500;
  justify-content: center;
}

.cartitems-product-icon {
  height: 100px;
}

.cart-size {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}

.cartitems-remove-item {
  font-size: 1.5rem;
  cursor: pointer;
}

.cartitems-quantity-btn {
  justify-content: center;
  width: fit-content;
  border: 2px solid #ebebeb;
  border-radius: 3px;
  background: white;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 5px 10px 5px 10px;
}

.cartitems-quantity {
  font-size: 1.3rem;
}

.cartitems-quantity-minus {
  border-right: none;
  cursor: pointer;
}

.cartitems-quantity-plus {
  cursor: pointer;
}

.cartitems-down {
  display: flex;
  margin-top: 2rem;
}

.cartitems-down-right {
  display: block;
  margin: 40px 0px;
  width: 45%;
}

.cartitems-total {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 5vw;
  width: 100%;
}

.cartitems-total hr {
  width: 100%;
}

.cartitems-total-item {
  display: flex;
  margin: 0;
  justify-content: space-between;
}

.cartitems-promocode {
  flex: 1;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
}

.cartitems-promocode p {
  color: #555;
}

.cartitems-promobox {
  width: 100%;
  margin-top: 15px;
  height: 58px;
  background: var(--container-color);
  border-radius: 4px;
  border: 1px solid var(--title-color);
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cartitems-promobox input {
  background: transparent;
  font-size: 20px;
  width: 70%;
  height: 50px;
  border: none;
  outline: none;
  padding-left: 1rem;
}
.cartitems-promobox button {
  width: 30%;
  height: 58px;
  cursor: pointer;
  font-size: 16px;
  background: var(--title-color);
  color: white;
  border: none;
  border-right: 1px solid var(--title-color);
  outline: none;
  margin-right: 0px;
}

.cartitems .hidden {
  display: none;
}

.cartitems-checkout-btn {
  background-color: var(--subtitle-color);
  width: 100%;
  height: 4rem;
  margin-top: 0.5rem;
  letter-spacing: 2px;
}

.empty-cart {
  display: grid;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
}

.empty-cart p {
  margin-bottom: 1rem;
}

.cart-empty-button {
  background-color: var(--title-color);
  color: white;
  width: 10rem;
}

.cartitems .pd-shipping-box {
  margin-top: 3rem;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 1rem;
  display: grid;
  row-gap: 0.7rem;
  width: 90%;
  background-color: var(--container-color);
}

.cartitems .pd-shipping-line {
  font-family: Poppins;
}

.cartitems .pd-shipping-icon {
  color: black;
  padding: 5px;
  padding-right: 1rem;
  font-size: 1.3rem;
}

.cartitems-up-small-content {
  display: none;
  width: 100%;
  grid-template-columns: 2fr 4fr 1fr;
  gap: 1.5rem;
}

.cartitems-up-small-img {
  width: 100%;
}

.cartitems-up-small-description {
  width: 100%;
  row-gap: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cartitems-up-small-description p {
  margin: 0;
}

.cartitems-up-small-description-info {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.cartitems-up-small-description-info p {
  margin: 0px;
}

.cartitems-up-small-right {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.cartitems-up-small-right-total {
  font-weight: 800;
  font-size: 1.2rem;
}

.cartitems-up-small-right-remove {
}
@media screen and (max-width: 1400px) {
  .cartitems {
    width: 90vw;
  }
  .cartitems-format-main {
    gap: 30px;
  }
}
@media screen and (max-width: 900px) {
  .cartitems-format-main {
    gap: 10px;
  }

  .cartitems-up-main {
    display: none;
  }

  .cartitems-up-small-content {
    display: grid;
  }
}

@media screen and (max-width: 800px) {
  .cartitems-down {
    display: grid;
    grid-template-columns: 1fr;
  }

  .cartitems-down-right {
    display: block;
    margin: 40px 0px;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .cartitems-up-small-content {
    gap: 1rem;
  }
}

@media screen and (max-width: 450px) {
  .cartitems-up-small-description-info {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
  }

  .cartitems-up-small-content {
    display: grid;
    width: 100%;
    grid-template-columns: 2.5fr 4fr 1fr;
    gap: 1rem;
  }
}
